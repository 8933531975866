import React from 'react';
import { graphql } from 'gatsby';

import ImageGallery from 'react-image-gallery';
import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from "react-image-gallery/styles/css/image-gallery.css";

const GalleryTemplate = ({ data, location }) => {

    const siteTitle = data.site.siteMetadata.title
    const page = data.markdownRemark;
    const pageAst = page.htmlAst

    const isElement = (e) => e.type === 'element';
    const isTag = (tagName) => (e) => e.tagName === tagName;

    // I sure hope remarker never changes.
    const images = pageAst.children[0].children.filter(isElement).map(
        child => {
            const image = child.children.filter(isTag('a'))[0].children.filter(isTag('img'))[0].properties;
            return {
                original: image.src,
                thumbnail: image.src,
                srcSet: image.srcSet.join(','),
                sizes: image.sizes.join(','),
                description: image.alt,
            };
        }
    );

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={page.frontmatter.title}
            />
            <ImageGallery
                items={images}
                showBullets={true}
            />
        </Layout>
    );
}

export default GalleryTemplate;

export const pageQuery = graphql`
  query GalleryByPath($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
          title
      }
    }
  }
`
